import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const originalReplace = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalReplace.call(this, location).catch(err => err);
};
const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "about" */ '../views/index.vue')
  },
  // {
  //   // 首页
  //   path: '/index',
  //   name: 'index',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/index.vue')
  // },
  { // 简介
    path: '/brief',
    name: 'brief',
    component: () => import(/* webpackChunkName: "about" */ '../views/brief.vue')
  },
  { // 园区
    path: '/park',
    name: 'park',
    component: () => import(/* webpackChunkName: "about" */ '../views/parkbase.vue')
  },
  { // 基地
    path: '/base',
    name: 'base',
    component: () => import(/* webpackChunkName: "about" */ '../views/parkbase.vue')
  },
  { // 文章详情
    path: '/detail',
    name: 'detail',
    component: () => import(/* webpackChunkName: "about" */ '../views/detail.vue')
  },
  { // 资讯信息文章列表
    path: '/infoList',
    name: 'infoList',
    component: () => import(/* webpackChunkName: "about" */ '../views/parkbase.vue')
  },
  { // 会员中心
    path: '/memberCenter',
    name: 'memberCenter',
    component: () => import(/* webpackChunkName: "about" */ '../views/memberCenter.vue')
  },
  { // 服务
    path: '/service',
    name: 'service',
    component: () => import(/* webpackChunkName: "about" */ '../views/service.vue')
  },
  { // 报名
    path: '/signIn',
    name: 'signIn',
    component: () => import(/* webpackChunkName: "about" */ '../views/signIn.vue')
  },
  { // 文章列表, 从会员中心进
    path: '/memberCenterList',
    name: 'memberCenterList',
    component: () => import(/* webpackChunkName: "about" */ '../views/parkbase.vue')
  },
  { // 登录
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  },
  { // 注册
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "about" */ '../views/register.vue')
  },
  { // 活动
    path: '/activity',
    name: 'activity',
    component: () => import(/* webpackChunkName: "about" */ '../views/activity.vue')
  },
  { // 会员服务
    path: '/memberService',
    name: 'memberService',
    component: () => import(/* webpackChunkName: "about" */ '../views/memberService.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: "/fenghui",
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
